.table-rank{
  text-align: center;
}

.table-rows td{
  font-size: .9em;
}

.table-occupation{
  max-width: 200px;
  font-weight: 400;
  padding-left: 15px;
  padding-right: 5px;
}

.table-education{
	max-width: 150px;
}

.bold-cell{
  font-weight: 600 !important;
}

.rank-value{
	font-weight: 600;
}