.subscribe-div > div > input[type="email"]{
	width: 200px !important;
	margin-right: 10px;
	margin-bottom: 20px;
	color: white !important;
	height: 30px;
}

.subscribe-div > div > button{
 	background-color: white !important;
 	border: 2px solid grey !important;
 	height: 30px;
 	width: 80px;
}

.subscribe-div > div > button:active, button:focus{
 	background: #42a1f4 !important;
}

.subscribe-div > div > div {
	color: white !important;
	font-weight: bold;
}


.subscribe-div{
	margin-top: 30px !important;
}

@media only screen and (max-width: 600px) {
	.subscribe-div > div > button{
		border: 2px solid lightgrey !important;
		height: 24px;
	}
}
