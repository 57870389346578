.pagination{
	cursor: pointer;
}

.pagination li.active {
    background-color: #4b636e !important;
}

.loading-div{
	margin-top: 60px;
}

.loading-text{
	margin-bottom: 30px;
}

.notes-row{
	text-align: center;
}

.data-notes{
	margin-top: 0px;
	margin-bottom: 0px;
	font-variant: small-caps;
	text-decoration: underline;
	text-align: center !important;
}

.options-row{
  margin: 5px auto 0px auto;
  max-width: 800px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px;
}

.options-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  height: 40px !important;
}

.options-wrapper:not(:last-child) {
  margin-right: 15px;
}

.options-row label{
	font-size: 1em;
	color: #0f0f0f;
	line-height: 1em !important;
	margin-right: 15px;
}

.search-box {
	height: 36px !important;
	width: 220px !important;
	margin-top: 0px !important;
	margin-bottom: 0px !important;
	font-size: 1em !important;
	box-shadow: none !important;
	border: 1px rgb(204, 204, 204) solid !important;
	border-radius: 4px !important;
	padding-left: 10px !important;
	padding-right: 10px !important;
}

.search-box::placeholder { 
	color: grey;
}

.search-box:focus{
	border: 2px #2e87fb solid !important;
}

.category-select input {
	height: 15px !important;
	line-height: 15px !important;
}

.category-select {
  box-shadow: none !important;
  border-radius: 4px;
  min-width: 320px;
  text-align: left;
  font-size: 1em;
}

@media only screen and (max-width: 785px) {
	.options-wrapper{
		margin: 0 auto !important;
	}

	.options-wrapper:not(:last-child) {
	   margin-bottom: 10px !important;
	}
}

.sort-caption{
	font-size: 0.85em;
	margin-top: 0px;
	margin-bottom: 5px;
	font-style: italic;
}

.table-headings th {
	text-transform: uppercase;
	color: grey;
	font-size: 0.8em;
	font-weight: 400;
	padding: 5px;
}

.table-headings i{
	margin-left: 4px;
}

@media only screen and (max-width: 785px) {
	.table-headings i{
		display: none;
	}
}


.table-headings i:hover{
	color: orange !important;
}


@media only screen and (min-width: 785px) {
	.data-superscript, .score-explanation {
		display: none;
	}
}

table.striped>tbody>tr:nth-child(odd) {
    
}

.heading-sort-link:hover{
	text-decoration: underline !important; 
	cursor: pointer;
}

.active-heading{
	font-weight: bold;
}

.record-counter{
	margin-top: 0px;
	margin-bottom: 5px;
}

.source-text{
	margin-bottom: 0px;
}

.legend-col{
	margin: 0 auto;
	margin-top: 10px !important;
	max-width: 400px;
}

.legend-col p{
	font-size: 0.8em;
	margin-top: 1px;
	margin-bottom: 1px;
	text-align: left;
}

#title_index{
	float: left;
}
.sort-icon{
	float: right;
}

.div-sort{
	clear: both;
}