body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=number]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]){
  border-color: #3e98ec;
  box-shadow: none;
}

input[type=text]:not(.browser-default):focus:not([readonly]) + label,
input[type=number]:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label,
input[type=email]:not(.browser-default):focus:not([readonly]) + label,
input[type=password]:not(.browser-default):focus:not([readonly]) + label{
  color: #3e98ec !important;
}

body {
  display: -webkit-flex !important;
  display: flex !important;
  min-height: 100vh !important;
  -webkit-flex-direction: column !important;
          flex-direction: column !important;
}

main {
  -webkit-flex: 1 0 auto !important;
          flex: 1 0 auto !important;
}
.header-div{
  text-align: center;
}

.brand-logo{
  font-size: 2em !important;
  margin-top: 20px;
  margin-bottom: 0px;
  font-weight: 500;
}

.brand-subtitle{
  margin-top: 6px;
  font-size: 1em !important;
}

@media only screen and (max-width: 800px) {
  .brand-logo{
    width: 100%;
    text-align: center;
  }

}


@media only screen and (max-width: 2000px){
  nav .brand-logo {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}


.nav-wrapper{
  padding-left: 20px;
  padding-right: 20px;
}

.subscript-version{
  font-size: 0.4em;
  margin-left: -8px;
}

@media only screen and (max-width: 600px) {
  .container{
    width: 100%;
  }

  .nav-wrapper{
    padding-left: 0px;
    padding-right: 0px;
  }

}

@media only screen and (min-width: 600px) {
  .container{
    margin: 0 auto;
    width: 98%;
  }

}

@media only screen and (min-width: 1300px) {
  .container{
    margin: 0 auto;
    width: 85%;
  }

}



.row{
  margin-bottom: 0px;
}

nav {
  box-shadow: none !important;
}
.pagination{
	cursor: pointer;
}

.pagination li.active {
    background-color: #4b636e !important;
}

.loading-div{
	margin-top: 60px;
}

.loading-text{
	margin-bottom: 30px;
}

.notes-row{
	text-align: center;
}

.data-notes{
	margin-top: 0px;
	margin-bottom: 0px;
	-webkit-font-feature-settings: "c2sc";
	        font-feature-settings: "c2sc";
	font-variant: small-caps;
	text-decoration: underline;
	text-align: center !important;
}

.options-row{
  margin: 5px auto 0px auto;
  max-width: 800px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 15px;
}

.options-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 10px;
  height: 40px !important;
}

.options-wrapper:not(:last-child) {
  margin-right: 15px;
}

.options-row label{
	font-size: 1em;
	color: #0f0f0f;
	line-height: 1em !important;
	margin-right: 15px;
}

.search-box {
	height: 36px !important;
	width: 220px !important;
	margin-top: 0px !important;
	margin-bottom: 0px !important;
	font-size: 1em !important;
	box-shadow: none !important;
	border: 1px rgb(204, 204, 204) solid !important;
	border-radius: 4px !important;
	padding-left: 10px !important;
	padding-right: 10px !important;
}

.search-box::-webkit-input-placeholder { 
	color: grey;
}

.search-box::-ms-input-placeholder { 
	color: grey;
}

.search-box::placeholder { 
	color: grey;
}

.search-box:focus{
	border: 2px #2e87fb solid !important;
}

.category-select input {
	height: 15px !important;
	line-height: 15px !important;
}

.category-select {
  box-shadow: none !important;
  border-radius: 4px;
  min-width: 320px;
  text-align: left;
  font-size: 1em;
}

@media only screen and (max-width: 785px) {
	.options-wrapper{
		margin: 0 auto !important;
	}

	.options-wrapper:not(:last-child) {
	   margin-bottom: 10px !important;
	}
}

.sort-caption{
	font-size: 0.85em;
	margin-top: 0px;
	margin-bottom: 5px;
	font-style: italic;
}

.table-headings th {
	text-transform: uppercase;
	color: grey;
	font-size: 0.8em;
	font-weight: 400;
	padding: 5px;
}

.table-headings i{
	margin-left: 4px;
}

@media only screen and (max-width: 785px) {
	.table-headings i{
		display: none;
	}
}


.table-headings i:hover{
	color: orange !important;
}


@media only screen and (min-width: 785px) {
	.data-superscript, .score-explanation {
		display: none;
	}
}

table.striped>tbody>tr:nth-child(odd) {
    
}

.heading-sort-link:hover{
	text-decoration: underline !important; 
	cursor: pointer;
}

.active-heading{
	font-weight: bold;
}

.record-counter{
	margin-top: 0px;
	margin-bottom: 5px;
}

.source-text{
	margin-bottom: 0px;
}

.legend-col{
	margin: 0 auto;
	margin-top: 10px !important;
	max-width: 400px;
}

.legend-col p{
	font-size: 0.8em;
	margin-top: 1px;
	margin-bottom: 1px;
	text-align: left;
}

#title_index{
	float: left;
}
.sort-icon{
	float: right;
}

.div-sort{
	clear: both;
}
.table-rank{
  text-align: center;
}

.table-rows td{
  font-size: .9em;
}

.table-occupation{
  max-width: 200px;
  font-weight: 400;
  padding-left: 15px;
  padding-right: 5px;
}

.table-education{
	max-width: 150px;
}

.bold-cell{
  font-weight: 600 !important;
}

.rank-value{
	font-weight: 600;
}
.page-footer{
	margin-top:30px;
	padding-top: 1px;
}
.subscribe-div > div > input[type="email"]{
	width: 200px !important;
	margin-right: 10px;
	margin-bottom: 20px;
	color: white !important;
	height: 30px;
}

.subscribe-div > div > button{
 	background-color: white !important;
 	border: 2px solid grey !important;
 	height: 30px;
 	width: 80px;
}

.subscribe-div > div > button:active, button:focus{
 	background: #42a1f4 !important;
}

.subscribe-div > div > div {
	color: white !important;
	font-weight: bold;
}


.subscribe-div{
	margin-top: 30px !important;
}

@media only screen and (max-width: 600px) {
	.subscribe-div > div > button{
		border: 2px solid lightgrey !important;
		height: 24px;
	}
}

