.header-div{
  text-align: center;
}

.brand-logo{
  font-size: 2em !important;
  margin-top: 20px;
  margin-bottom: 0px;
  font-weight: 500;
}

.brand-subtitle{
  margin-top: 6px;
  font-size: 1em !important;
}

@media only screen and (max-width: 800px) {
  .brand-logo{
    width: 100%;
    text-align: center;
  }

}


@media only screen and (max-width: 2000px){
  nav .brand-logo {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}


.nav-wrapper{
  padding-left: 20px;
  padding-right: 20px;
}

.subscript-version{
  font-size: 0.4em;
  margin-left: -8px;
}

@media only screen and (max-width: 600px) {
  .container{
    width: 100%;
  }

  .nav-wrapper{
    padding-left: 0px;
    padding-right: 0px;
  }

}

@media only screen and (min-width: 600px) {
  .container{
    margin: 0 auto;
    width: 98%;
  }

}

@media only screen and (min-width: 1300px) {
  .container{
    margin: 0 auto;
    width: 85%;
  }

}



.row{
  margin-bottom: 0px;
}

nav {
  box-shadow: none !important;
}